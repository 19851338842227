<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="py-5" elevation="8">
          <h1 class="text-center primary--text">
            Boletos para o CPF/CNPJ {{ $store.state.cpf }}
          </h1>
          <h4 class="text-center">
            Data:
            {{
              new Date()
                .getDate()
                .toString()
                .padStart(2, "0")
            }}/ {{ (new Date().getMonth() + 1).toString().padStart(2, "0") }}/
            {{ new Date().getFullYear() }}
          </h4>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" md="4" v-for="boleto in boletos" :key="boleto.id">
        <v-card class="mx-auto" max-width="344">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">{{ $store.state.credor_atual }}</div>
              <v-list-item-title class="headline mb-1"
                >Boleto</v-list-item-title
              >
              <v-list-item-subtitle>
                <div class="text--primary">
                  Valor R$ {{ boleto.valor | dinheiro }}
                </div>
                <div class="text--primary">
                  Vencimento {{ boleto.venc | dataF }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn
              text
              color="deep-purple accent-4"
              @click="mostrarBoleto(boleto.id)"
            >
              Ver Boleto
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Operacao2",
  data() {
    return {
      boletos: null,
    };
  },
  async beforeMount() {
    this.boletos = this.$store.state.operacaoBoletos;
    // console.log(this.boletos);
  },
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    dataF: function(d2) {
      let d = d2.substr(0, 10);
      return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
    },
  },
  methods: {
    mostrarBoleto(id) {
      let boleto = this.boletos.filter((v) => {
        return v.id == id;
      });
      //console.log(boleto[0]);
      this.$store.dispatch("guardaBoleto", boleto[0]);
      this.$router.push("/boletooperacaoalgar");
    },
  },
};
</script>

<style></style>
